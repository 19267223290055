.feather {
    width: 16px;
    height: 16px;
    vertical-align: text-bottom;
}

/*
   * Sidebar
   */

.sidebar {
    position: fixed !important;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    /* Behind the navbar */
    padding: 48px 0 0 !important;
    /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
    
    .sidebar.show {
        width: 100%;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
}

#mySearch {
    font-size: 14px;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-sticky {
        position: -webkit-sticky;
        position: sticky;
    }
}

.sidebar .nav-link {
    font-weight: 500;
    color: #333;
    font-size: 14px;
}

.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}

.sidebar .nav-link.active {
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    background-color: #007bff52;
    color: #000;
    border-radius: 5px;
}

.sidebar .nav-link.active:hover {
    background-color: #007bff;
    color: #fff;
}

.sidebar .nav-link:hover {
    background-color: #007bff52;
    color: #000;
    border-radius: 5px;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

/*
   * Navbar
   */

.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/* Dropdown Button */
.dropdown-link {
    font-weight: 500;
    color: #333;
    padding: 16px;
    font-size: 16px;
    border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 200px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

@media (max-width: 550.98px) {
    .dropdown:hover .dropdown-content {
        left: 0;
        display: block;
    }

    .navbar-dropdown-box {
        display: block;
        width: 50%;

    }
}

.navbar-dropdown-box {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffffff;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}



/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
    right: 0;
}

.menu-title {
    letter-spacing: .05em;
    padding: 10px 20px;
    pointer-events: none;
    cursor: default;
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    padding-left: 10px;
    background-color: #d3d3d3;
}

[data-bs-theme=dark] .sidebar .nav-link {
    color: #fff;
}

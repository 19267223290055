body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.table> :not(:first-child) {
    border-top: 1px solid !important;
}

.table-bordered td {
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.rotate {
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.search-box,
.close-icon,
.search-wrapper {
    position: relative;
    padding: 10px;
}

.search-box {
    width: -webkit-fill-available;
    border: 1px solid #ccc;
    outline: 0;
    border-radius: 8px;
    padding: 0.375rem 0.75rem;
    padding-right: 42px;
}

.search-box:focus {
    border: 2px solid lightblue;
}

[data-bs-theme=dark] #date_range {
    background-color: var(--bs-body-bg) !important;
}

[data-bs-theme=dark] .rdrDefinedRangesWrapper {
    background-color: var(--bs-body-bg) !important;
}

[data-bs-theme=dark] .rdrDefinedRangesWrapper, .rdrInputRangeInput {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] .rdrStaticRanges, .rdrStaticRangeLabel {
    background-color: var(--bs-body-bg) !important;

    :hover {
        background-color: #007bff52 !important;
        color: white;
    }
}

[data-bs-theme=dark] .css-yk16xz-control, .css-1pahdxg-control, .css-1fhf3k1-control {
    background-color: var(--bs-body-bg) !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

[data-bs-theme=dark] .css-1uccc91-singleValue {
    color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] .css-26l3qy-menu {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] .css-1n7v3ny-option {
    background-color: #007bff52 !important;
    color: white;
}

/* Ensuring high specificity by targeting class names directly */
.react-date-picker {
    background-color: var(--bs-body-bg) !important;
    /* Bootstrap dark background */
    color: var(--bs-body-color) !important;
    /* Bootstrap light text color */
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    /* Darker border */
    border-radius: 0.25rem !important;
}

.react-date-picker__wrapper {
    /* Dark background */
    background-color: var(--bs-body-bg) !important;
    /* Light text color */
    color: var(--bs-body-color) !important;
    /* Darker border */
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.react-date-picker__inputGroup__input {
    /* Dark input background */
    background-color: var(--bs-body-bg) !important;
    /* Light text color */
    color: var(--bs-body-color) !important;
    border: none !important;
}

.react-calendar {
    /* Dark background */
    background-color: var(--bs-body-bg) !important;
    /* Light text color */
    color: var(--bs-body-color) !important;
    /* Darker border */
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    border-radius: 0.25rem !important;
}

.react-calendar__month-view__weekdays {
    /* Slightly lighter dark background for weekdays */
    background-color: var(--bs-body-bg) !important;
}

.react-calendar__tile {
    /* Dark background for tiles */
    background-color: var(--bs-body-bg) !important;
    /* Light text color */
    color: var(--bs-body-color) !important;
    /* Border for tiles */
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
}

.react-calendar__tile--active {
    /* Active tile background */
    background-color: var(--bs-body-bg) !important;
    /* Active tile text color */
    color: var(--bs-body-color) !important;
}

.react-calendar__tile--now {
    /* Today’s date border */
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    /* Today’s date background */
    background-color: var(--bs-body-bg) !important;
}

.react-calendar__tile--hover {
    /* Hovered tile background */
    background-color: #007bff52 !important;
    /* Hovered tile text color */
    color: white !important;
}

.react-calendar__navigation button {
    /* Navigation buttons color */
    color: var(--bs-body-color) !important;
    /* Navigation buttons background */
    background-color: var(--bs-body-bg) !important;
    border: none !important;
}

[data-bs-theme=dark] .rdrDateDisplayWrapper {
    background-color: var(--bs-body-bg) !important;
}

[data-bs-theme=dark] .rdrDateInput input {
    background-color: var(--bs-body-bg) !important;
        color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] .rdrDateRangeWrapper {
    background-color: var(--bs-body-bg) !important;
    color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] .rdrMonthAndYearPickers select {
    color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] .rdrMonthAndYearPickers select:hover {
    background-color: rgba(var(--bs-body-bg-rgb), 0.07) !important;
}

[data-bs-theme=dark] .rdrDayNumber span {
    color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] [id^="react-select-"][id$="-input"] {
    color: var(--bs-body-color) !important;
}

[data-bs-theme=dark] [class^="css-"] svg {
    fill: var(--bs-body-bg) !important;
}
